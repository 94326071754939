import type { ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';

// NOTE: Also see useStoryblokStories

export async function useAsyncStoryblokStories(name: string, parameters: ISbStoriesParams) {
    const api = useStoryblokApi();
    const { transform } = useStoryblokTransformer();

    const { data } = await useAsyncData(
        name,
        () => api.getStories({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...parameters,
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
        {
            transform: (response) => response.data.stories.map((story) => ({
                ...usePick(story, [
                    'name',
                    'id',
                    'uuid',
                    'slug',
                    'full_slug',
                    'position',
                ]),
                content: transform(story.content),
            })) as ISbStoryData[],
        },
    );

    return data.value || [];
}
